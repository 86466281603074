.ant-table-filter-trigger {
  color: var(--color-main);
  &:hover {
    color: var(--color-main);
  }
}

.ant-table-column-sorter {
  color: var(--color-main);
}

.ant-table-thead {
  user-select: none;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & .bell {
    cursor: pointer;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.3s;

    &:hover {
      background-color: var(--color-header-second);
    }
  }

  & .label {
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 10px;
    font-weight: bold;
    width: 15px;
    height: 15px;
    padding: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    color: var(--color-green);
    background: var(--badge-bg-green);
  }
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--color-mainBg);
  opacity: 0.35;
  cursor: pointer;
  z-index: 5;
}

.ant-dropdown {
  color: var(--color-main);
  & .ant-table-filter-dropdown {
    background-color: var(--color-mainBg);
    color: var(--color-main);
    & .ant-dropdown-menu {
      background-color: var(--color-mainBg);
      color: var(--color-main);
      & .ant-dropdown-menu-item {
        color: var(--color-main);
        background-color: var(--color-mainBg);
        &.ant-dropdown-menu-item-active,
        &.ant-dropdown-menu-item-selected {
          background-color: var(--color-elemBg);
        }
        & .ant-checkbox-wrapper + span {
          color: var(--color-main);
        }
      }
    }
    & .ant-btn {
      min-height: auto;
    }
  }
}

.container {
  position: fixed;
  width: 100vw;
  z-index: 10;
  padding: 0 40px;
  background-color: var(--color-header-main);
  border-bottom: 1px solid var(--color-header-second);
  box-shadow: var(--shadow-header);

  & .top {
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    & .status {
      display: flex;
      align-items: center;
      margin: 0 20px;
      font-size: 80%;
      font-weight: bold;
      min-height: 36px;
      color: var(--color-green);
      padding: 8px;
      border-radius: 4px;
      background-color: var(--badge-bg-green);
      flex-shrink: 0;
    }

    & .search {
      margin-left: 5px;
    }

    & .subscription {
      display: flex;
      align-items: center;
      margin: 0 20px;
      font-size: 80%;
      min-height: 36px;
      font-weight: bold;
      color: var(--color-red);
      padding: 8px;
      border-radius: 4px;
      background-color: var(--badge-bg-red);
    }

    & .menu {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 8px;

      & .dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;
        transition: all 0.3s;
        border-radius: 5px;
        color: var(--color-font);

        &:hover {
          background-color: var(--color-header-second);
        }

        & .backdrop {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 10px;

          .icon {
            height: 22px;

            svg {
              padding: 3px;
              border-radius: 5px;
            }
          }

          & b {
            font-weight: 500;
          }
        }
      }

      & .switcher {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        gap: 10px;
        padding: 0 10px;
        border-radius: 5px;
        transition: all 0.3s;

        & button {
          width: 40px;
          height: 20px;

          &:focus {
            outline: none;
            box-shadow: none !important;
          }
        }

        &:hover {
          background-color: var(--color-header-second);
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .container {
    padding: 0 20px;

    & .top {
      & .search {
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    & .top {
      & .search {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 555px) {
  .container {
    padding: 0 10px;

    & .top {
      & .status {
        font-size: 70%;
        margin: 0 8px;
        padding: 5px;
        min-height: 30px;
      }

      & .menu {
        gap: 5px;

        & .dropdown {
          & .backdrop {
            & b {
              display: none;
            }
          }
        }
      }
    }
  }
}

.TabsUi {
  &.default {
    &:global(.ant-tabs) {
      color: var(--color-font);
      & :global(.ant-tabs-nav) {
        &::before {
          border: none;
        }
        & :global(.ant-tabs-tab) {
          color: var(--color-font);
          font-weight: bold;

          & :global(.ant-tabs-tab-btn) {
            color: var(--color-font);
          }
          &:global(.ant-tabs-tab-active) {
            & :global(.ant-tabs-tab-btn) {
              color: var(--color-green);
            }
          }
        }
      }
      & :global(.ant-tabs-ink-bar) {
        background: var(--color-green);
      }
    }
  }
  &.malyaStyle {
    &:global(.ant-tabs) {
      color: var(--color-font);
      font-family: 'Nunito', sans-serif;
      & :global(.ant-tabs-nav) {
        margin: 0;
        padding: 5px;
        width: 100%;

        & :global(.ant-tabs-ink-bar) {
          display: none;
        }

        & :global(.ant-tabs-tab) {
          & :global(.ant-tabs-tab-btn) {
            width: 100%;
            color: var(--color-font);
            font-weight: bold;

            & a {
              color: var(--color-font);
            }
          }
        }
      }

      & :global(.ant-tabs-nav::before) {
        border-bottom: none;
      }

      & :global(.ant-tabs-tab) {
        height: 50px;
        padding: 0 !important;
        border-radius: 10px;
        transition: all 0.3s;

        & :global(.ant-tabs-tab-btn) {
          & :global(.ant-dropdown-trigger),
          & a {
            position: relative;
            display: flex;
            align-items: center;
            padding: 13px;

            svg {
              margin-right: 10px;
            }
          }

          & :global(.ant-space-item) {
            & span {
              display: flex;
              align-items: center;
            }

            & :global(.arrow) {
              position: absolute;
              right: 0;
              top: calc(50% - 8px);
              & path {
                fill: var(--color-font);
              }
            }
          }
        }

        &:hover {
          background-color: var(--color-elemBg);

          & a,
          & .ant-space-item {
            color: var(--color-main) !important;
          }

          & .ant-space-item {
            transition: all 0.3s;
          }
        }
      }

      & :global(.ant-tabs-tab-active) {
        background-color: var(--color-elemBg);

        & :global(a),
        & :global(.ant-space-item) {
          color: var(--color-main) !important;
        }
      }

      & :global(.ant-tabs-content-holder) {
        display: none;
      }
    }

    // & :global(.ant-tabs-tab + .ant-tabs-tab) {
    //   margin: 5px 0 0 0 !important;
    // }
  }
}

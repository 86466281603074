// @import '../../node_modules/antd/dist/antd.css';
@import 'light.theme';
@import 'dark.theme';
@import './antd/antd.scss';
@import './var.scss';
* {
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

//tiny error message disabled
.tox-notifications-container {
  display: none;
}

a {
  color: var(--color-blue);
}

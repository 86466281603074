.buttonUi,
.buttonUi.ant-btn:hover,
.buttonUi.ant-btn:focus,
.buttonUi.ant-btn:active {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: var(--font-size);
  font-weight: bold;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  padding: 10px;
  font-family: 'Nunito', sans-serif !important;
  //!important ломает стили кастомных кнопок
  color: var(--color-font);
  background: var(--color-mainBg);
  box-shadow: none;

  svg {
    position: relative;
    top: -1px;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  color: var(--color-grey) !important;
  opacity: 0.5;
  background: var(--color-elemBg) !important;
}

.buttonUi:hover {
  opacity: 0.5;
}

.buttonUi.buttonUi_main,
.buttonUi[disabled].buttonUi_main {
  //!important ломает стили кастомных кнопок
  color: var(--color-main);
}

.buttonUi.buttonUi_red,
.buttonUi[disabled].buttonUi_red {
  color: var(--color-red) !important;
}

.buttonUi.buttonUi_green,
.buttonUi[disabled].buttonUi_green {
  color: var(--color-green) !important;
}

.buttonUi.buttonUi_yellow,
.buttonUi[disabled].buttonUi_yellow {
  color: var(--color-yellow) !important;
}

.buttonUi.buttonUi_grey,
.buttonUi[disabled].buttonUi_grey {
  color: var(--color-grey) !important;
}

.buttonUi.outlined,
.buttonUi[disabled].outlined {
  background: transparent;
}

.buttonUi_highlighted {
  -webkit-box-shadow: 0px 0px 1px 0px var(--color-green);
  -moz-box-shadow: 0px 0px 1px 0px var(--color-green);
  box-shadow: 0 0 0px 1px var(--color-green);
}
